<template>
  <div
    class="modal fade"
    id="modalChangeTheme"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalChangeThemeLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header" v-if="!selectedTheme">
          <h5 class="modal-title" id="modalChangeThemeLabel">
            <i class="bi bi-columns-gap"></i>
            {{
              $t(
                "Components.ChangeThemeModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body"
          :class="{
            'p-0': selectedTheme,
            'bg-warning': selectedTheme && !selectedThemeApplySuccess,
            'bg-success': selectedTheme && selectedThemeApplySuccess,
          }"
        >
          <template v-if="selectedTheme">
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="theme-card mt-0 p-3 col-6 theme-card-selected">
                <img
                  :src="require(`@/assets/img/themes/${selectedTheme.id}.png`)"
                  class="img-fluid"
                  :alt="selectedTheme.name"
                />
                <div
                  class="alert alert-warning fs-3 mt-1"
                  v-if="!selectedThemeApplySuccess"
                >
                  {{
                    $t(
                      "Components.ChangeThemeModal.WarningText",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  <span
                    class="spinner-border fs-4"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="alert alert-success fs-3 mt-1" v-else>
                  {{
                    $t(
                      "Components.ChangeThemeModal.PleaseThreeSecond",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  <span
                    class="spinner-border fs-4"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="bg-light border p-2 w-100 mb-3 mt-0">
              <div class="row">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="me-3 fs-4">
                    {{
                      $t(
                        "BaseModelFields.Search",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}:
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      :autocomplete="$isAutoComplete"
                      :spellcheck="$isTextSpellCheck"
                      @keyup="filterTheme()"
                      v-model="themeSearchQuery"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="theme-cards row" v-if="this.$parent.activeTheme">
              <div
                class="theme-card col-md-3 col-xs-12 col-sm-6 mb-4"
                :id="`theme-${theme.id}`"
                v-for="(theme, i) in themes.filter(
                  (f) => f.id !== this.$parent.activeTheme.id
                )"
                :key="i"
              >
                <img
                  :src="require(`@/assets/img/themes/${theme.id}.png`)"
                  class="img-fluid"
                  :alt="theme.name"
                />
                <span class="d-none theme-card-name">{{ theme.name }}</span>
                <div class="theme-card-front">
                  <div class="text-center">
                    <button
                      class="btn btn-success mt-2 mb-3 btn-theme-card-toggle"
                      @click="setTheme(theme)"
                    >
                      <i class="bi bi-columns-gap"></i>
                      {{
                        $t(
                          "Components.ChangeThemeModal.Apply",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </div>
                  <p v-if="theme.description">${theme.description}</p>
                </div>
                <div class="theme-card-back">
                  <div class="alert alert-warning mb-2">
                    {{
                      $t(
                        "Components.ChangeThemeModal.ApproveMessage",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </div>
                  <div class="text-center mb-2">
                    <button
                      type="button"
                      class="btn btn-success me-2 btn-apply-theme"
                      @click="setThemeApprove(theme)"
                    >
                      <span>
                        <i class="fa fa-check"></i>
                        {{
                          $t(
                            "BaseModelFields.Yes",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-apply-theme-cancel"
                      @click="setThemeCancel"
                    >
                      <i class="fa fa-times"></i>
                      {{
                        $t(
                          "BaseModelFields.No",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer" v-if="!selectedTheme">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import { hideModal } from "@/core/helpers/dom";
export default {
  name: "ChangeThemeModal",
  data() {
    return {
      bootswatch: this.$root.bootswatch,
      themes: this.$root.bootswatch.items,
      selectedTheme: null,
      selectedThemeApplySuccess: false,
      themeSearchQuery: "",
    };
  },
  methods: {
    filterTheme() {
      var value = this.themeSearchQuery.toLowerCase();
      $(".theme-cards .theme-card").filter(function () {
        var parentDiv = $(this);
        parentDiv.toggle(
          $(this)
            .find(".theme-card-name")
            .text()
            .trim()
            .toLowerCase()
            .indexOf(value) > -1
        );
      });
    },
    closeAllApproveBlocks() {
      $(`.theme-cards .theme-card`).removeClass("flip");
    },
    setTheme(theme) {
      this.closeAllApproveBlocks();
      $(`#theme-${theme.id}`).addClass("flip");
    },
    setDefaultValueForSelectedTheme() {
      this.selectedTheme = null;
      this.selectedThemeApplySuccess = false;
      this.themeSearchQuery = "";
    },
    setThemeApprove(theme) {
      this.selectedTheme = theme;
      this.$appAxios
        .post(`/Lcdp-ChangeUserActiveTheme/${theme.id}`)
        .then((response) => {
          var result = response.data;

          this.selectedThemeApplySuccess = result.isOk;

          if (result.isOk) {
            this.$store.commit("setTheme", theme.id);
            this.$parent.setActiveTheme();
            this.$root.applyTheme();

            setTimeout(() => {
              this.setDefaultValueForSelectedTheme();
              hideModal(document.getElementById("modalChangeTheme"));
              createToast(
                this.$t(
                  "Components.ChangeThemeModal.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("THEME_NAME", theme.name),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
            }, 3000);
          } else {
            this.setDefaultValueForSelectedTheme();
            createToast(result.message, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          // alert(error);
        });
    },
    setThemeCancel() {
      this.closeAllApproveBlocks();
    },
  },
};
</script>
